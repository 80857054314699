:root {
  --white-1: #ffffff;

  --background: #fafbfc;
  --green-1:#E6F7EC;
  --green-2:#CCF0D9;
  --green-3:#99E0B3;
  --green-4:#66D18C;
  --green-5:#33C165;
  --green-6:#009951;
  --green-7:#00893D;
  --green-8:#007829;
  --green-9:#006815;
  --green-10: #005700;

  --blue-1: #00287B;
  --blue-2: #00339A;
  --blue-3: #003EB8;
  --blue-4: #0049D7;
  --blue-5: #0053F5;
  --blue-6: #3385FF;
  --blue-7: #66A3FF;
  --blue-8: #99C2FF;
  --blue-9: #CCE0FF;
  --blue-10: #E6F0FF;

  --yellow-1: #FFF200;
  --yellow-2: #FCE400;
  --yellow-3: #F8D500;
  --yellow-4: #F5C600;
  --yellow-5: #F1B700;
}

html,
body,
#root,
#homePage {
  height: 100%;
  background-color: var(--white-1);
}


#homePage {
  height: 100%;
  background-color: var(--background);
}


/* Cards */

.card-quotes{
  display: flex;
  flex-direction: column;
  height: 100%;
}
.card-content-quotes{
  padding: 10px !important;
}

/* Text */

.titles,
.subtitle,
.titles-pages {
  color: var(--green-8);
}

.titles-pages{
  text-transform: uppercase;
  font-weight: 600 !important;
}

.bar-title{
  text-transform: capitalize !important;
}

.font-icons-list {
  font-size: 1.2rem;
}

.link-password {
  color: var(--blue-5) !important;
}

.link-password:hover {
  color: var(--blue-1) !important;
  font-weight: 600;
}

.link-password:visited {
  color: var(--blue-1) !important;
}

.Mui-disabled{
  color: black !important;
  -webkit-text-fill-color: black !important;
}

/* Login */

.login {
  background-image: url('assets/img/background-login.jpg');
  background-position: center;
  background-size: cover;
}

/* Buttons */

.quotesBtn{
  color: white !important;
  background-color: var(--green-6) !important;
  font-weight: 550 !important;
  width: 100%;
}

.successBtn {
  color: white !important;
  background-color: var(--green-6) !important;
  font-weight: 550 !important;
  height: 50px;
  width: 150px;
}

.AlertButton {
  color: black !important;
  font-weight: bold !important;
  background-color: var(--yellow-1) !important;
}

.BackButton {
  color: white !important;
  font-weight: bold !important;
  background-color: var(--blue-1) !important;
}

.Btnfilter {
  height: 100%;
  padding: 15px !important;
}

/* Formulario Inputs */

.MuiFormControl-root {
  width: 100%;
  margin-top: 10px !important;
}

/* Grids */

.grid-filter-button {
  padding: 15px 0px 0px 5px !important;
}

.section-banner {
  justify-content: center;
}

/* Icon */

.faCircleCheck {
  font-size: 1.2rem;
  color: var(--green-2) !important;
}

.faCircleXmark {
  font-size: 1.2rem;
  color: #920707 !important;
}

.ListBtn {
  color: #00287b !important;
}

@media screen and (max-width: 900px) {

  .titles-pages {
    color: var(--green-8);
    font-size: 1.4rem;
  }

  .quotesBtn{
    color: white !important;
    background-color: var(--green-6) !important;
    font-weight: 550 !important;
    width: 80%;
  }
}

/* CSS DA DIV MASCARA */
#mascara{
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 9000;
  height: 100%;
  width: 100%;
  background: url(assets/img/loading.gif) center center no-repeat rgb(0 0 0 / 75%);
  display: none;
}