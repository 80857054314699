.carousel-image{
  width: 100% !important;
  padding: 1px;
}

.slick-dots {
  position: absolute;
  bottom: -10px !important;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}