.grid-carousel-card{
  height: 100% !important;
  display: flex;
  align-content: space-between;
}

.carousel-card-content {
  height: 210px;
  padding: 5px !important;
  text-align: center;
}

.carousel-card{
  height: 100% !important;
}

.slick-prev:before,
.slick-next:before {
  color: #333;
  font-size: 25px;
}

.card-carousel .slick-dots li button:before {
  color: #333; /* Cor padrão dos pontos */
  font-size: 12px; /* Tamanho ajustado dos pontos */
}

.card-carousel .slick-dots li.slick-active button:before {
  color: #4caf50; /* Cor verde para o ponto ativo */
}

.slick-next {
  right: 10px; /* Ajusta a posição da seta direita */
  z-index: 1000; /* Garante que a seta esteja sobre o conteúdo */
}

.slick-prev {
  left: 10px; /* Ajusta a posição da seta esquerda */
  z-index: 1000; /* Garante que a seta esteja sobre o conteúdo */
}

/* Crops */

.cropIcon{
  width: 32px;
  height: 32px;
  text-align: center;
}
